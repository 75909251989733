<template>
	<!--	@todo check with Benjamin if hide or prevent user from resizing window-->
	<v-fade-transition>
		<v-banner v-if="!isMobileAppBannerClosed" :class="{ desktop: isDesktop, mobile: isMobile }" color="primary" lines="one">
			<!--			// @todo ask Benjamin how to overwrite css slot properties-->
			<template #prepend>
				<sk-icon-btn v-if="isMobile" :icon="$fa('close')" color="neutral-text" @click="dismissMobileAppBanner" />
				<v-img v-if="isDesktop" :class="{ 'responsive-hidden': isDesktop }" :src="SilaeTelSVG" width="30" />
			</template>

			<v-banner-text v-if="isDesktop" class="d-flex flex-column">
				<h3 class="responsive-hidden">{{ $t('common.components.mobile_app.banner.desktop.title') }}</h3>
				<p class="responsive-hidden">{{ $t('common.components.mobile_app.banner.desktop.subtitle') }}</p>
			</v-banner-text>

			<v-img v-if="isMobile" class="silae-tel-mobile" :src="LogoStoreSVG" width="50" />
			<v-banner-text v-if="isMobile" class="d-flex w-100 justify-space-between">
				<p class="d-flex flex-row text-default-text font-weight-bold align-self-center">
					{{ $t('common.components.mobile_app.download') }}
				</p>
				<a :href="storeURL" target="_blank">
					<sk-primary-btn class="banner-action-button">
						{{ $t('common.components.mobile_app.open') }}
					</sk-primary-btn>
				</a>
			</v-banner-text>

			<div v-if="isDesktop" class="badges-container d-flex flex-row">
				<a class="align-self-center" :href="$t('common.components.mobile_app.store.ios')" target="_blank">
					<v-img :src="AppStoreBadgeSVG" width="144" />
				</a>
				<a :href="$t('common.components.mobile_app.store.android')" target="_blank">
					<v-img :src="GooglePlayBadgePNG" width="150" />
				</a>
			</div>

			<template v-if="isDesktop" #actions>
				<v-divider :thickness="1" vertical />
				<sk-icon-btn class="ml-1" :icon="$fa('close')" color="neutral-text" @click="dismissMobileAppBanner" />
			</template>
		</v-banner>
	</v-fade-transition>
</template>

<style lang="scss" scoped>
.desktop {
	background-color: var(--sk-primary-surface-1-hex);
	margin: var(--sk-spacing-md) auto 0;
	width: calc(100% - (2 * var(--sk-spacing-md)));
	border-radius: var(--sk-border-radius-sm);
	//@todo add promoting banner height to tokens
	max-height: 68px;
	padding-bottom: 55px;
}

.mobile {
	padding: var(--sk-spacing-md) var(--sk-spacing-xs);
	position: fixed;
	bottom: 0;
	box-shadow:
		0 4px 8px 3px rgba(0, 0, 0, 0.15),
		0 1px 3px 0 rgba(0, 0, 0, 0.3);
	z-index: 2000;
}

.badges-container {
	position: absolute;
	// @todo ask @Benjamin how to define value of closing icon + divider
	right: calc(58px + var(--sk-spacing-sm));
	top: 5px;
	gap: var(--sk-spacing-sm);
}
.silae-tel-mobile {
	margin-left: -15px;
	margin-right: var(--sk-spacing-sm);
}
.responsive-hidden {
	@media screen and (max-width: 768px) {
		display: none;
	}
}
.v-banner-text {
	padding-inline-end: var(--sk-spacing-xs) !important;
}

.banner-action-button {
	min-width: fit-content !important;
	margin-left: var(--sk-spacing-sm);
}
</style>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { VBanner, VBannerText, VDivider, VFadeTransition, VImg } from 'vuetify/components';

import { SkIconBtn, SkPrimaryBtn } from '@silae/components';
import AppStoreBadgeSVG from '~/assets/app-store-badge.svg';
import GooglePlayBadgePNG from '~/assets/google-play-badge.png';
import LogoStoreSVG from '~/assets/logo-store.svg';
import SilaeTelSVG from '~/assets/silae-tel.svg';
import { dismissMobileAppBanner, isMobileAppBannerClosed } from '~/composables';
import { $fa, Devices } from '~/plugins';

const isMobile = Devices().isIOS || Devices().isAndroid;
const isDesktop = Devices().isDesktop;
let storeURL = null;
const { t } = useI18n();

if (isMobile) {
	storeURL = Devices().isIOS ? t('common.components.mobile_app.store.ios') : t('common.components.mobile_app.store.android');
}
</script>
