import { Observable } from 'rxjs';

import { useBackendHttpService } from '~/api/http';

import { ADMIN_BASE_URL } from '../defs';
import { InstitutionDTO } from './institutions.dto';

const API = 'institutions';

export function fetchInstitutions$(companyId: number): Observable<Array<InstitutionDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}`);
}
