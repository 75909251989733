<template>
	<v-tooltip :max-width="maxWidth" :text="title" data-test-id="tooltip-icon-button" location="top">
		<template #activator="{ props }">
			<sk-icon-btn
				:class="iconClass"
				:color
				:data-test-id="$attrs['data-test-id']"
				:disabled
				:icon
				:loading
				:size
				:variant
				v-bind="props"
				@click="click"
			/>
		</template>
	</v-tooltip>
</template>

<script lang="ts" setup>
import { VTooltip } from 'vuetify/components';

import { SkIconBtn } from '@silae/components';
import { useEventPropagation } from '@silae/composables';
import { UiSize } from '@silae/types';

defineProps({
  color: null,
  disabled: { type: Boolean },
  icon: null,
  iconClass: null,
  size: { default: UiSize.SM },
  title: null,
  variant: { default: 'text' },
  loading: { type: Boolean },
  maxWidth: { default: 200 }
});

defineEmits(['click']);
const { click } = useEventPropagation(['click']);
</script>
