import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const OnboardingRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_ONBOARDING,
	path: 'onboarding',
	component: () => import('./employee-onboarding/employee-onboarding.vue'),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasOnboardingAdminFeature.value)
	}
};

export const OffboardingRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_OFFBOARDING,
	path: 'offboarding',
	component: () => import('./employee-offboarding/employees-offboarding.vue'),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasOffboardingAdminFeature.value)
	}
};

export const AdminEmployeeLifecycleRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_LIFECYCLE,
	path: 'lifecycle',
	components: {
		default: () => import('./employee-lifecycle.vue'),
		...defaultLayoutComponents
	},
	children: [OnboardingRoute, OffboardingRoute],
	redirect: () => (useFeatures().hasOnboardingAdminFeature.value ? OnboardingRoute : OffboardingRoute),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(
				() =>
					!isAdmin.value ||
					Devices().isMobile ||
					!(useFeatures().hasOnboardingAdminFeature.value || useFeatures().hasOffboardingAdminFeature.value)
			);
		},
		isSubEntry: true,
		label: 'admin.navigation.employees-lifecycle',
		requiresAuth: true,
		order: 4
	}
};
