import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

const { konami } = useKonamiCode();

export const EmployeePersonalInformationRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_PERSONAL_INFORMATION,
	path: 'personal-information',
	component: () => import('./personal-information/employee-personal-information.vue'),
	props: route => ({ employeeId: Number(route.params.employeeId) })
};

export const EmployeeInformationRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_INFORMATION,
	path: 'information',
	component: () => import('./employee-information/employee-information.vue'),
	props: route => ({ employeeId: Number(route.params.employeeId) })
};

export const EmployeeContracts: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_CONTRACTS,
	path: 'contracts',
	component: () => import('./contracts-information/contracts-information.vue'),
	props: route => ({ employeeId: Number(route.params.employeeId) }),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasEmployeeDetails.value)
	}
};

export const EmployeeDetailsRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_DETAILS,
	path: '/employees/details/:employeeId',
	props: true,
	components: {
		default: () => import('./employee-details.vue'),
		...defaultLayoutComponents
	},
	children: [EmployeePersonalInformationRoute, EmployeeInformationRoute, EmployeeContracts],
	redirect: to => ({ name: EmployeePersonalInformationRoute.name, params: { employeeId: to.params?.employeeId } }),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(
				() =>
					!isAdmin.value ||
					Devices().isMobile ||
					!konami.value ||
					!(useFeatures().hasEmployeeDetails.value || useFeatures().hasEmployeeDetailsRestricted.value)
			);
		},
		requiresAuth: true
	}
};
