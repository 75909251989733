import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { AdminEmployeeLifecycleRoute } from './employee-lifecycle/employee-lifecycle.route';
import { AdminEmployeeListRoute } from './employee-list/employee-list.route';

export const AdminEmployeeManagementRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_MANAGEMENT,
	path: '/employees',
	components: {
		default: () => import('./employees.vue'),
		...defaultLayoutComponents
	},
	children: [AdminEmployeeListRoute, AdminEmployeeLifecycleRoute],
	redirect: AdminEmployeeListRoute,
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(
				() =>
					!isAdmin.value ||
					Devices().isMobile ||
					!(
						useFeatures().hasEmployeeDetails.value ||
						useFeatures().hasEmployeeDetailsRestricted.value ||
						useFeatures().hasOnboardingAdminFeature ||
						useFeatures().hasOffboardingAdminFeature
					)
			);
		},
		isNavEntry: true,
		label: 'admin.navigation.employee-management',
		icon: 'group_avatar',
		requiresAuth: true,
		order: 40
	}
};
