export enum Feature {
	AUTOMATIC_EARNED_WAGE_ACCESSES_ONBOARDING = 'AUTOMATIC_EARNED_WAGE_ACCESSES_ONBOARDING',
	AUTOMATIC_EARNED_WAGE_ACCESSES_REQUEST = 'AUTOMATIC_EARNED_WAGE_ACCESSES_REQUEST',
	BLOCK_HALF_DAY_FOR_CP = 'BLOCK_HALF_DAY_FOR_CP',
	EMPLOYEE_DETAILS = 'EMPLOYEE_DETAILS',
	EMPLOYEE_DETAILS_RESTRICTED = 'EMPLOYEE_DETAILS_RESTRICTED',
	INTERVIEWS_ADMIN = 'INTERVIEWS_ADMIN',
	LEAVE_DAY_ADMIN_FULL = 'LEAVE_DAY_ADMIN_FULL',
	LEAVE_DAY_SUPERVISE = 'LEAVE_DAY_SUPERVISE',
	LEAVE_DAY_VIEW_CREATE = 'LEAVE_DAY_VIEW_CREATE',
	LEAVE_DAY_VIEW_READ = 'LEAVE_DAY_VIEW_READ',
	MEETINGS_ADMIN = 'MEETINGS_ADMIN',
	MEETINGS_LOGIN = 'MEETINGS_LOGIN',
	ONBOARDING_ADMIN = 'ONBOARDING_ADMIN',
	OFFBOARDING_ADMIN = 'OFFBOARDING_ADMIN',
	OWN_MANAGER = 'OWN_MANAGER',
	PAY_FOLDER = 'PAY_FOLDER', // ??
	PAYSLIP_FULL_COMPANY_VIEW = 'PAYSLIP_FULL_COMPANY_VIEW',
	PAYSLIP_NO_VALIDATION = 'PAYSLIP_NO_VALIDATION',
	PAYSLIP_ORIGINAL_VIEW = 'PAYSLIP_ORIGINAL_VIEW',
	PAYSLIP_VIEW = 'PAYSLIP_VIEW',
	SALARY_TRANSFER = 'SALARY_TRANSFER',
	TEAMS_ADMIN = 'TEAMS_ADMIN',
	VARIABLE_ELEMENTS_BONUSES = 'VARIABLE_ELEMENTS_BONUSES',
	VARIABLE_ELEMENTS_EARNED_WAGE_ACCESS = 'VARIABLE_ELEMENTS_EARNED_WAGE_ACCESS',
	VARIABLE_ELEMENTS_HOURS = 'VARIABLE_ELEMENTS_HOURS',
	VARIABLE_ELEMENTS_VALIDATION = 'VARIABLE_ELEMENTS_VALIDATION'
}
