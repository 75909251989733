import { lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouteLocationNormalized, createRouter, createWebHistory } from 'vue-router';

import { showMobileAppBanner, useGuardedRoutes } from '~/composables';
import { Devices } from '~/plugins';
import { useTrackingService } from '~/services';
import { useAuthenticationStore } from '~/stores';

import { routes } from './routes';
import { RouteKeys } from './routes.domain';

export enum RedirectionCauseEnum {
	USER_NOT_AUTHENTICATED,
	FORBIDDEN
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes
});

router.beforeEach(async (to, from, next) => {
	const store = useAuthenticationStore();
	const { isForbidden } = useGuardedRoutes();

	const isAuthenticationRequired = (to: RouteLocationNormalized) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (to.meta.requiresAuth && !store.isAuthenticated) {
				const principal = await lastValueFrom(store.refreshPrincipal$().pipe(catchError(() => of(null))));
				if (principal != null) {
					resolve(true);
				} else {
					reject(RedirectionCauseEnum.USER_NOT_AUTHENTICATED);
				}
			} else {
				resolve(true);
			}
		});
	};

	const isAllowed = (to: RouteLocationNormalized) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (!isForbidden(to)) {
				resolve(true);
			} else {
				reject(RedirectionCauseEnum.FORBIDDEN);
			}
		});
	};

	try {
		await isAuthenticationRequired(to);
		await isAllowed(to);
		next();
	} catch (error) {
		switch (error as RedirectionCauseEnum) {
			case RedirectionCauseEnum.USER_NOT_AUTHENTICATED:
				next({ name: RouteKeys.SIGN_IN, query: { redirect: to.fullPath } });
				break;
			case RedirectionCauseEnum.FORBIDDEN:
				next({ name: RouteKeys.HOME });
				break;
		}
	}
});

router.afterEach((to, from, failure) => {
	if (!failure) {
		useTrackingService().trackPageView();

		if (Devices().isIOS || Devices().isAndroid) {
			showMobileAppBanner();
		}
	}
});

export { router };
